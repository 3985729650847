import React from "react";
import Logo from '../../images/shields-blue.svg'
import {Link} from "gatsby";


const Footer = () => (
  <footer id="footer" className="footer">
    <div className="footer__inner">
      <div className="footer__logo">
        <img src={Logo} />
      </div>
      <nav className="footer__nav">
        <ul className="nav">
          <li>
            <Link
              to="/"
              className=""
            >Homepage</Link>
          </li>
          <li>
            <Link className="" to="/faqs">FAQs</Link>
          </li>
          <li>
            <Link to="https://www.bcbs.com/terms-conditions" target={"_blank"}>Terms and Conditions</Link>
          </li>
        </ul>
      </nav>
    </div>
    <div className="footer__copyright">
      <p className="roman-12">© 2024 Blue Cross Blue Shield Association. All Rights Reserved.<br />
      The Blue Cross Blue Shield Association is an association of independent, locally operated Blue Cross and Blue Shield companies.</p>
    </div>
  </footer>
)

export default Footer;
